<template>
  <div class="Input_root">
    <div class="Input_labelRow">
      <label
        v-if="label"
        :for="name"
      >
        {{ label }}
      </label>
      <div
        class="Input_rightPart"
        v-if="rightLabelValue"
      >
        {{ rightLabelString }}
        <span class="Input_rightValue">{{ rightLabelValue }}</span>
        <div
          v-if="rightLabelFee"
          class="Input_rightValueFee"
        >({{ rightLabelFee.toFixed( 2 ) }})
        </div>
      </div>
    </div>
    <div
      :class="[
        'Input_inputRow',
        {
          ['Input_hasRightBtnCopy']: showBtnCopy,
          ['Input_hasRightBtn']: !!btnText,
          ['Input_hasError']: !isValid,
        },
      ]"
    >
      <input
        :style="disabledColor ? `color: ${disabledColor}` : ''"
        ref="text"
        :type="type"
        :name="name"
        :value="value"
        @input="onChange"
        :disabled="disabled"
        :autocomplete="autocomplete"
      />
      <button
        v-if="btnText"
        :disabled="disabled"
        @click="handleClick"
      >
        <iconQr v-if="showQrIcon"/>
        {{ btnText }}
      </button>
      <button
        v-if="showBtnCopy"
        @click="handleClick"
        class="copyBtn"
      >
        <iconCopy/>
      </button>
    </div>
  </div>
</template>

<script>
import { iconQr } from "@/assets/icons/common";
import { iconCopy } from "@/assets/icons/common";

export default {
  components: {
    iconQr,
    iconCopy
  },
  props: {
    label: {
      type: String,
      default: ""
    },
    rightLabelString: {
      type: String,
      default: ""
    },
    rightLabelValue: {
      type: String,
      default: ""
    },
    rightLabelFee: {
      type: Number,
      default: null
    },
    type: {
      type: String,
      default: "text"
    },
    name: {
      type: String,
      required: true
    },
    value: {
      type: [ String, Number ],
      default: ""
    },
    disabled: {
      type: Boolean,
      default: false
    },
    disabledColor: {
      type: String
    },
    btnText: {
      type: String,
      default: ""
    },
    shouldCopyValue: {
      type: Boolean,
      default: false
    },
    showQrIcon: {
      type: Boolean,
      default: false
    },
    showBtnCopy: {
      type: Boolean,
      default: false
    },
    rules: {
      type: Array,
      default: () => []
    },
    autocomplete: {
      type: String,
      default: "on"
    }
  },
  computed: {
    isValid() {
      if ( this.rules && this.rules[ 0 ] ) {
        if ( this.$refs && this.$refs.text ) {
          const value = this.$refs.text.value;
          if ( typeof this.rules[ 0 ]( value ) === "boolean" ) {
            return this.rules[ 0 ]( value );
          }
          return false;
        }
        return false;
      }
      return true;
    }
  },
  methods: {
    onChange( e ) {
      const value = e.target.value;
      this.$emit( "input", value );
    },
    handleClick( e ) {
      e.preventDefault();
      this.$emit( "clickBtn" );
    }
  }
};
</script>

<style lang="scss" scoped>
@import "./Input.scss";
</style>
