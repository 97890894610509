<template>
  <section class="ScanCode_root">
    <transition
      name="fade"
      mode="out-in"
    >
      <div
        class="ScanCode_wrapper"
        v-if="qrCode"
      >
        <p class="ScanCode_step">Step 2</p>
        <div class="ScanCode_qr">
          <img
            v-if="qrCode"
            :src="formatQrToSrc"
          />
        </div>
        <div class="ScanCode_key">
          <div class="ScanCode_keyTitle">google key</div>
          <div class="ScanCode_keyInput">
            <Input
              name="google_key"
              :value="clipboardKey"
              btnText="COPY KEY"
              shouldCopyValue
              disabled
              @clickBtn="handleCopyAddress"
            />
          </div>
        </div>
        <Button
          class="btn-large btn-bright btn-full-width btn-bright-large btn !text-[12px]"
          text="next step"
          :btnArrowRight="true"
          size="large"
          @click="handleSubmit"
        ></Button>
      </div>
      <div class="ScanCode_loader" v-else>
        <Loader/>
      </div>
    </transition>
  </section>
</template>

<script>
import Button from '@/components/_common/FormElements/Button/Button';
import Loader from '@/components/_common/Loader/Loader';
import Input from '@/components/_common/FormElements/Input/Input';
import { copyToClipboard } from '@/utils/helpers';
import { mapActions } from 'vuex';
export default {
  components: {
    Button,
    Input,
    Loader,
  },
  data: () => ({
    copyToClipboard,
    qrCode: '',
    clipboardKey: '',
  }),
  computed: {
    formatQrToSrc() {
      return `data:image/png;base64, ${ this.qrCode }`
    },
  },
  methods: {
    ...mapActions('user', ['getGoogleTotpCode']),
    handleSubmit() {
      this.$emit('submit', 'confirm')
    },
    handleCopyAddress() {
      copyToClipboard(this.clipboardKey)
    },
  },
  mounted() {
    this.getGoogleTotpCode().then(res => {
      this.qrCode = res.qr_code_file_data
      this.clipboardKey = res.setup_key
    })
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/_colors.scss';
.ScanCode_step {
  letter-spacing: 0.04em;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  color: $primaryText;
  margin: 0 0 20px 0;
}

.ScanCode_key {
  margin: 0 0 20px 0;
}

.ScanCode_keyTitle {
  letter-spacing: 0.04em;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  color: $primaryText;
  margin: 0 0 14px 0;
  text-align: left;
}

.ScanCode_qr {
  background: #1f4d64;
  width: 206px;
  height: 206px;
  margin: 0 auto 30px;
  border-radius: 4px;

  img {
    display: block;
    width: 100%;
    height: 100%;
  }
}

.ScanCode_loader {
  height: 425px;
}
</style>
