<template>
  <section class="DownloadApp_root">
    <div class="DownloadApp_wrapper">
      <p class="DownloadApp_step">Step 1</p>
      <p class="DownloadApp_description">Download and setap application for Google Authenticator</p>
      <div class="DownloadApp_applications">
        <a target="_blank" href="https://apps.apple.com/us/app/google-authenticator/id388497605" class="DownloadApp_application">
          <AppStore fillColor="#2AF3F3" />
          <div class="DownloadApp_applicationDescription">
            Download from
            <span>App Store</span>
          </div>
        </a>
        <a
          target="_blank"
          href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2"
          class="DownloadApp_application"
        >
          <GooglePlay fillColor="#2AF3F3" />
          <div class="DownloadApp_applicationDescription">
            Download from
            <span>Google Play</span>
          </div>
        </a>
      </div>
      <Button
        class="btn-large btn-bright btn-full-width btn-bright-large btn !text-[12px]"
        text="next step"
        btnArrowRight
        brightBtn
        size="large"
        @click="handleSubmit"
      ></Button>
    </div>
  </section>
</template>

<script>
import { AppStore, GooglePlay } from '@/assets/icons/common';
import Button from '@/components/_common/FormElements/Button/Button';
export default {
  components: {
    AppStore,
    GooglePlay,
    Button,
  },
  props: {},
  data: () => ({}),
  methods: {
    handleSubmit() {
      this.$emit('submit', 'scan_code');
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/_colors.scss';
.DownloadApp_application {
  display: flex;
  align-items: center;
  max-width: 200px;
  height: 84px;
  width: 100%;
  justify-content: center;
  text-decoration: none;
  border-radius: 4px;
  background: #1f4960;
  transition: 0.25s all ease-in-out;
  margin: 0 10px;
  path {
    transition: 0.25s all ease-in-out;
  }
  &:hover {
    background: #a5ffff;
    box-shadow: 0px 0px 10px rgb(42 243 243 / 55%);
    path {
      fill: #2d4d70;
    }
    .DownloadApp_applicationDescription {
      color: #2d4d70;
    }
  }
}
.DownloadApp_applicationDescription {
  display: flex;
  transition: 0.25s all ease-in-out;
  flex-direction: column;
  align-items: flex-start;
  margin: 0 0 0 15px;
  font-size: 14px;
  line-height: 16px;
  color: $primaryLanding;
  span {
    margin: 6px 0 0 0;
    font-weight: bold;
    font-size: 21px;
    line-height: 25px;
  }
}
.DownloadApp_applications {
  display: flex;
  justify-content: center;
  margin: 0 0 30px 0;
}
.DownloadApp_step {
  letter-spacing: 0.04em;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  color: $primaryText;
  margin: 0 0 20px 0;
}
.DownloadApp_description {
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: $primaryText;
}
</style>
