<template>
  <div class="security-root">
    <div class="security__auth">
      <div class="Google__authenticator py-[25px] px-[20px] bg-white dark:bg-blue-800/[0.4] rounded-[4px] shadow-app-section-root dark:shadow-component-wrapper-root max-w-[460px] flex items-center ">
        <img src="@/assets/img/auth/G2FA-logo.svg" alt="authenticator-icon" />
        <div class="text-left flex flex-col items-start ml-[8px] max-w-[240px]">
          <div class="font-medium text-[18px] leading-[21px] tracking-[0.04em] uppercase mb-[10px] text-black-300 dark:text-white">google authentication</div>
          <div class="font-normal text-[12px] leading-[14px] tracking-[0.04em] text-black-300 dark:text-gray-600">Used for improved security of withdrawals  and account access</div>
        </div>
        <div class="Google__authenticatorSwitcher">
          <v-switch
            :value="googleAuthSwitch"
            hide-details
            @change="onSwitchChange"
            inset
          ></v-switch>
        </div>
      </div>
      <div class="pt-[34px] flex flex-col items-start">
        <h3 class="text-[18px] leading-[21px] font-medium tracking-[0.04em] uppercase mb-[24px]">TELEGRAM BOT</h3>
        <div
          class="flex items-center"
          v-if="!telegramAccount"
        >
          <div class="w-[60px] h-[60px] mr-[20px] relative bg-blue-800 border-blue-500 border-[5px] shadow-generalShadow rounded-full p-[10px]">
            <img src="@/assets/img/auth/telegram-bot.svg" alt="authenticator-icon" class="w-full h-full object-contain pointer-events-none" />
          </div>
          <a
            class="!text-blue-300 dark:!text-blue-800 h-[38px] py-[8px] px-[18px] rounded-[2px] bg-black-300 dark:bg-blue-500 flex items-center tracking-[0.04em] uppercase decoration-0 font-bold text-[12px] leading-[14px] transition" href="https://t.me/kaurifinance_bot">
            <span class="w-[21px] h-[21px] rounded-[28px] bg-blue-300 dark:bg-blue-800 relative flex items-center justify-center mr-[8px]">
              <SvgIcon
                class="text-black-300"
                icon="iconPlus"
              ></SvgIcon>
            </span>
            Link the bot
          </a>
        </div>
        <div class="flex items-center" v-else>
          <div class="w-[60px] h-[60px] mr-[20px] relative bg-blue-800 border-blue-500 border-[5px] shadow-generalShadow rounded-full p-[10px]">
            <img src="@/assets/img/auth/telegram-bot.svg" alt="authenticator-icon" class="w-full h-full object-contain pointer-events-none" />
          </div>
          <div class="flex flex-col items-start">
            <div class="text-white opacity-40 text-[12px] leading-[14px] mb-[6px]">Telegram username</div>
            <div class="text-blue-500 font-medium text-[12px] leading-[14px] tracking-[0.04em]">{{ telegramAccount }}</div>
          </div>
          <div
            @click="deleteTelegramBot"
            class="Telegram__botDelete h-[38px] bg-[#1c576a] rounded-[4px] px-[15px] flex items-center text-blue-500 tracking-[0.04em] uppercase font-bold text-[12px] leading-[14px] ml-[27px] cursor-pointer transition"
          >
            Unlink the bot
            <SvgIcon
              icon="iconDelete"
            ></SvgIcon>
        </div>
      </div>
    </div>
    <transition name="fade" mode="out-in">
      <Modal
        v-if="securityModalIsShown"
        :styles="`max-width: 512px; width: 100%;`"
        @close="handleClose"
      >
        <div slot="content">
          <h2 class="pr-[34px] font-medium text-[21px] leading-[25px] tracking-[0.04em] uppercase mb-[8px] text-left">{{ activeStep.title }}</h2>
          <p class="text-[14px] leading-[16px] uppercase text-blue-500 text-left mb-[20px]">{{ activeStep.description }}</p>
          <div
            v-if="activeStep.id !== 'deactivate'"
            :class="['Auth_stepsProgeress', `Auth_stepsProgeress-${activeStep.id}`]"
          ></div>

          <component 
            :is="currentStepComponent" 
            :isStep3="currentStepComponent=='Confirm'" 
            :activeStepId="activeStep.id !== 'deactivate'" 
            @submit="handleNextStep" 
            @onClose="handleClose"
          ></component>
        </div>
      </Modal>
    </transition>
  </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import Modal from '@/components/_common/Modal/Modal';
import DownloadApp from '@/views/Application/Account/Settings/Security/AuthSteps/DownloadApp';
import ScanCode from '@/views/Application/Account/Settings/Security/AuthSteps/ScanCode';
import Confirm from '@/components/_common/TotpComponent/G2FAConfirm.vue';
import SvgIcon from "@/components/_common/SvgIcon";
import { iconPlus, iconDelete } from '@/assets/icons/common';

const STEPS_INFO = {
  download_app: {
    id: 'download_app',
    title: 'Enable Google Authentication',
    description: 'step 1. download application',
  },
  scan_code: {
    id: 'scan_code',
    title: 'Enable Google Authentication',
    description: 'step 2. scan code',
  },
  confirm: {
    id: 'confirm',
    title: 'Enable Google Authentication',
    description: 'step 3. confirm',
  },
  deactivate: {
    id: 'deactivate',
    title: 'Disable Google Authentication',
    description: '',
  },
};

export default {
  components: {
    SvgIcon,
    Modal,
    DownloadApp,
    ScanCode,
    Confirm,
    iconPlus,
    iconDelete,
  },
  data: () => ({
    googleAuthSwitch: false,
    activeStep: STEPS_INFO.download_app,
    securityModalIsShown: false,
  }),
  computed: {
    ...mapState('user', ['userTotpEnabled', 'userInfo']),
    currentStepComponent() {
      switch (this.activeStep.id) {
        case 'download_app':
          return 'DownloadApp';
        case 'scan_code':
          return 'ScanCode';
        case 'confirm':
          return 'Confirm';
        case 'deactivate':
          return 'Confirm';
        default:
          return 'DownloadApp';
      }
    },
    telegramAccount() {
      return this?.userInfo?.telegram_account || false;
    },
  },
  methods: {
    ...mapActions('settings', ['apiDeleteTelegramBot']),
    onSwitchChange(value) {
      this.securityModalIsShown = true;
      this.googleAuthSwitch = value;
      if (this.userTotpEnabled) {
        this.activeStep = STEPS_INFO.deactivate;
      } else {
        this.activeStep = STEPS_INFO.download_app;
      }
    },
    handleNextStep(step) {
      if (step === 'lastStep') {
        this.securityModalIsShown = false;
        this.keepSwitchValueUpdated();
      } else {
        this.activeStep = STEPS_INFO[step];
      }
    },
    handleClose() {
      this.keepSwitchValueUpdated();
      this.securityModalIsShown = false;
    },
    keepSwitchValueUpdated() {
      this.googleAuthSwitch = this.userTotpEnabled;
    },
    deleteTelegramBot() {
      this.apiDeleteTelegramBot({ username: this.telegramAccount });
    },
  },
  mounted() {
    this.keepSwitchValueUpdated();
  },
};
</script>

<style lang="scss">
@import '@/assets/scss/_colors.scss';
@import './Security.scss';
</style>
