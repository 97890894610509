<template>
  <section class="Confirm_root">
    <div class="Confirm_wrapper">
      <p class="Confirm_step" v-if="isStep3 && activeStepId">Step 3</p>
      <!-- TO DO: use GoogleTotpLogin -->
      <div class="Confirm_stepLabel">
        <img src="@/assets/img/auth/G2FA-logo.svg" alt="authenticator-icon" />
        <div class="Confirm_stepLabelDesc">
          <div class="Confirm_stepLabelDescTitle">google authentication</div>
          <div class="Confirm_stepLabelDescText">Enter the 6 digit code sent to Google authentication</div>
        </div>
      </div>
      <div class="Confirm_input">
        <!-- TO DO: move to components (this input used atleast 2x times) -->
        <otp-input
          ref="otp"
          v-model="authCode"
          :length="6"
          pattern="[^0-9]+"
          :ignorePattern="false"
          @change="handleOnChange"
        />
      </div>
      <div class="Confirm_btns">
        <div class="Confirm_btn">
          <OutlineButton
            class="outline-button-default outline-button-full-width outline-button"
            ref="submitButton"
            text="confirm"
            @click="handleSubmit"
            :disabled="disabledSubmit"
            @focus="setCheckedFocusOrBlurConfirm"
            @blur="setCheckedFocusOrBlurConfirm"
          ></OutlineButton>
        </div>
        <div class="Confirm_btn">
          <Button
            class="btn"
            text="cancel"
            size="large"
            fullWidth
            @click="handleClose"
          ></Button>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import Button from '@/components/_common/FormElements/Button/Button';
import OutlineButton from '@/components/_common/FormElements/OutlineButton/OutlineButton';

export default {
  components: {
    Button,
    OutlineButton,
  },
  props: {
    isStep3: {
      type: Boolean,
      default: false,
    },
    activeStepId: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    disabledSubmit: true,
    authCode: '',
    checkedFocusOrBlurConfirm: false,
  }),
  computed: {
    ...mapState('user', ['userTotpEnabled']),
  },
  methods: {
    ...mapActions('user', ['enableGoogleTotp', 'disableGoogleTotp', 'verifyGoogleAuthTotp']),
    handleSubmit() {
      // console.log('handleSubmit')
      // this.clearAuthCode()
      if (this.isStep3) {
        if (!this.userTotpEnabled) {
          this.enableGoogleTotp({ token: this.authCode })
            .then(() => this.$emit('submit', 'lastStep'))
            .catch(err => {
              // this.authCode = '';
              // this.$nextTick(() => this.$refs.otp.$refs['otpFields-0'].focus());
            });
        } else {
          this.disableGoogleTotp({ token: this.authCode })
            .then(() => this.$emit('submit', 'lastStep'))
            .catch(err => {
              // this.clearAuthCode()
            });
        }
      } else {
        this.$emit('confirm', this.authCode)
        // this.disabledSubmit = true
        // this.authCode = '';
        // this.$nextTick(() => this.$refs.otp.$refs['otpFields-0'].focus());
      }
      this.clearAuthCode()
    },
    clearAuthCode() {
      // console.log('clearAuthCode')
      this.$nextTick(() => this.$refs.submitButton.focusButton());
      this.authCode = '';
      this.disabledSubmit = true;
      this.$nextTick(() => this.$refs.otp.$refs['otpFields-0'].focus());
    },
    handleSubmitWindowKeyupEnter(event) {
      if (event.key === 'Enter' && !this.checkedFocusOrBlurConfirm) {
        this.handleSubmit();
      }
    },
    handleClose() {
      if (this.isStep3) {
        this.$emit('onClose');
      } else {
        this.$emit('cancel');
      }
    },
    handleOnChange(value) {
      this.authCode = value;
      this.disabledSubmit = this.authCode.length < 6;
      if (this.authCode.length === 6) {
        // this.$nextTick(() => this.$refs.submitButton.focusButton());
      } else {
        // this.$nextTick(() => this.$refs.otp.$refs[`otpFields-${this.authCode.length}`].focus());
        setTimeout(() => { this.$refs.otp.$refs[`otpFields-${this.authCode.length}`].focus() }, 50);
      }
    },
    setCheckedFocusOrBlurConfirm(boolean) {
      this.checkedFocusOrBlurConfirm = boolean;
    },
  },
  mounted() {
    // console.log('mounted')
    this.$nextTick(() => this.$refs.otp.$refs['otpFields-0'].focus());
    window.addEventListener('keyup', this.handleSubmitWindowKeyupEnter);
  },
  beforeDestroy() {
    window.removeEventListener('keyup', this.handleSubmitWindowKeyupEnter);
  },
};
</script>

<style lang="scss">
@import '@/assets/scss/_colors.scss';

.Confirm_root {
  .Confirm_step {
    letter-spacing: 0.04em;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    color: $primaryText;
    margin: 0 0 20px 0;
  }

  .Confirm_stepLabel {
    display: flex;
    align-items: center;
    padding: 23px 20px;
    background: #1f4960;
    border-radius: 4px;
    max-width: 390px;
    margin: 0 auto 30px auto;
  }

  .Confirm_stepLabelDesc {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
    margin-left: 8px;
  }

  .Confirm_stepLabelDescTitle {
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    color: $primaryLanding;
    margin: 0 0 10px 0;
  }

  .Confirm_stepLabelDescText {
    color: $primaryLanding;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.04em;
  }

  .Confirm_input {
    height: 30px;
    margin: 0 auto 80px;
    max-width: 390px;

    & > ul {
      padding: 0;
      display: grid !important;
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
      grid-template-rows: 1fr;
      gap: 0 8px;
      list-style-type: none;
      & > li {
        padding-bottom: 6px;
        position: relative;

        &::before {
          content: '';
          width: 100%;
          position: absolute;
          height: 4px;
          bottom: 0;
          left: 0;
          background: $primaryLanding;
          box-shadow: 0px 0px 10px rgba(42, 243, 243, 0.55);
        }

        input {
          padding: 0;
          height: 30px;
          width: 100%;
          font-size: 28px;
          width: 100%;
          padding: 0;
          outline: none;
          color: $primaryLanding;
          font-weight: 600;
          border-radius: 0 !important;
          border: none !important;
          text-align: center;

          &::-webkit-outer-spin-button,
          &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
          }

          -moz-appearance: textfield;
        }
      }
    }
  }

  .Confirm_btns {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    gap: 0 10px;
  }
}
</style>
